import '@otmandelieu/scss/app-tourism.scss';
import WpetList from '@otmandelieu/js/wpet/list';
import WpetSingle from '@otmandelieu/js/wpet/single';
import WpetTrip from '@otmandelieu/js/wpet/trip';

document.addEventListener( "DOMContentLoaded", function () {
    new WpetList();
    new WpetSingle();
    //if ( document.querySelectorAll( '.js-wpetTrip' ).length > 0 ) {
        new WpetTrip();
    //}
});
